.App {
  text-align: center;
  background-image: url("/img/background.jpg");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
/* 
.Hero {
} */
.icon {
  height: 30px;
  width: 30px;
  padding: 5px;
}

.name {
  font-size: 40px;
}
/* #icons {
  border-radius: 45%;
  padding: 2%;
  width: 90%;
  background-color: #afaa97a1;
} */